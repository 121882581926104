import IconClose from "../../icon/IconClose"

const DisableReg = ()=>{
    return(
        <div
            className="flex flex-col cursor-pointer p-2 rounded-lg text-sm border bg-red-100 border-red-400 shadow-md mb-2 justify-center items-center"
            
        >
            <div className="h-9 w-9 rounded-full bg-red-200 flex justify-center items-center mb-1">
            <IconClose className="w-7 h-7 text-red-500" />
            </div>
        </div>
    )
}
export default DisableReg