import { useEffect, useState } from "react"
import IconBack from "../icon/IconBack"
import useMyPin from "../hook/useMyPin"
const TransferPin = () => {
    const [amount, setAmount] = useState('')
    const [receiver, setReceiver] = useState('')
    const [selectedPin, setSelectedPin] = useState("");
    const { showPin, data } = useMyPin()
    useEffect(() => {
        showPin(localStorage.getItem("username"))
    }, [showPin])

    const handleChange = (event) => {
        setSelectedPin(event.target.value);
    };
    return (
        <div>
            <div className="flex bg-white p-4">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className={`w-9 me-3`} />
                </div>
                <p className="font-semibold">Transfer PIN Aktivasi</p>
            </div>
            <div className="bg-white m-4 p-3 w-100 border-2 border-gray-100 shadow-md rounded-lg flex flex-col overflow-auto">
                <div className="mb-4">
                    <label className="etxt-semibold mb-1">PIN Aktivasi <span className="text-red-500">*</span></label>
                    <select
                        value={selectedPin}
                        onChange={handleChange}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        required
                    >
                        <option value={0}>Pilih Paket</option>
                        {data && Array.from(new Set(data.map(item => item.name))).map((name, index) => (
                            <option key={index} value={name}>{name}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="etxt-semibold mb-1">Jumlah PIN <span className="text-red-500">*</span></label>
                    <input type="number"
                        name="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        placeholder="0"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="etxt-semibold mb-1">Penerima <span className="text-red-500">*</span></label>
                    <input type="text"
                        name="receiver"
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        placeholder="Username Penerima"
                        required
                    />
                </div>
                <button type="submit"
                    className="w-100 mt-2 text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Submit
                </button>
            </div>
        </div>
    )
}
export default TransferPin