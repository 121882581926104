import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Menu = ({url,label,Icon}) => {
    const location = useLocation();
    const isActive = location.pathname === url;
    return (
        <div className="flex flex-col justify-center items-center text-[10px] cursor-pointer">
            <Link to={url}>
                <div className={`flex flex-col justify-center items-center hover:text-blue-500 ${isActive ? 'text-blue-500' : 'text-gray-300'}`}>
                    <Icon className={`${isActive ? 'text-blue-500' : 'text-gray-400'}`} />
                    <p className={`${isActive ? 'text-blue-500' : 'text-gray-400'}`}>{label}</p>
                </div>
            </Link>
        </div>
    );
};

export default Menu;
