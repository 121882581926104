const IconHistory = ({className}) => {
    return (
        <svg width="24" height="24" className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M20 9.84009C20.55 9.84009 21 10.2901 21 10.8401V17.0001C21 20.0001 19.5 22.0001 16 22.0001H8C4.5 22.0001 3 20.0001 3 17.0001V10.8401C3 10.2901 3.45 9.84009 4 9.84009H20Z" fill="currentColor" />
            <path d="M16.7502 3.56V2C16.7502 1.59 16.4102 1.25 16.0002 1.25C15.5902 1.25 15.2502 1.59 15.2502 2V3.5H8.75024V2C8.75024 1.59 8.41024 1.25 8.00024 1.25C7.59024 1.25 7.25024 1.59 7.25024 2V3.56C4.55024 3.81 3.24023 5.42 3.04023 7.81C3.02023 8.1 3.26023 8.34 3.54023 8.34H20.4602C20.7502 8.34 20.9902 8.09 20.9602 7.81C20.7602 5.42 19.4502 3.81 16.7502 3.56Z" fill="currentColor" />
            <path d="M15.6609 18.47L14.9209 17.73C15.3009 17.15 15.5309 16.45 15.5309 15.7C15.5309 13.66 13.8709 12 11.8309 12C9.79086 12 8.13086 13.66 8.13086 15.7C8.13086 17.74 9.79086 19.4 11.8309 19.4C12.5809 19.4 13.2809 19.17 13.8609 18.79L14.6009 19.53C14.7509 19.68 14.9409 19.75 15.1309 19.75C15.3209 19.75 15.5109 19.68 15.6609 19.53C15.9509 19.24 15.9509 18.76 15.6609 18.47Z" fill="currentColor" />
        </svg>

    )
}
export default IconHistory