import React from "react";
import { useState,useEffect } from "react";
import useUser from "../hook/useUser";
import Loading from "../components/Loading";
import { FormatRupiah } from "@arismun/format-rupiah";

const Bonus = () => {
    const [username, setUsername] = useState("");
    const { showUser, loading, error, data } = useUser();

    useEffect(() => {
        setUsername("sebara");
      }, []);
    useEffect(()=>{
        showUser(username);
    },[username,showUser])
    if(loading){
        return(<Loading/>)
    }
    if(error){
        return(
            <div className="w-full p-3 bg-red-500 rounded-lg text-center">
                {error}
            </div>
        )
    }
    return (
        <div>
            <p className="text-center font-bold">Rekap Bonus</p>
            <p className="text-xs text-center mb-3">Rekapitulasi pendapatan bonus sampai dengan hari ini</p>
            <div className="bg-white shadow-md p-2 w-full border border-gray-200 rounded-lg flex flex-col mb-2">
                <div className="flex justify-between text-sm">
                    <p>Bonus Sponsor</p>
                    <p>{data ? <FormatRupiah value={data.bonus_sponsor}/>:"Rp. 0"}</p>
                </div>
            </div>
            <div className="bg-white shadow-md p-2 w-full border border-gray-200 rounded-lg flex flex-col mb-2">
                <div className="flex justify-between text-sm">
                    <p>Bonus Pasangan</p>
                    <p>{data ? <FormatRupiah value={data.bonus_pasangan}/>:"Rp. 0"}</p>
                </div>
            </div>
            <div className="bg-white shadow-md p-2 w-full border border-gray-200 rounded-lg flex flex-col mb-2">
                <div className="flex justify-between text-sm">
                    <p>Bonus Titik</p>
                    <p>{data ? <FormatRupiah value={data.bonus_titik}/>:"Rp. 0"}</p>
                </div>
            </div>
            <div className="bg-white shadow-md p-2 w-full border border-gray-200 rounded-lg flex flex-col mb-2">
                <div className="flex justify-between text-sm">
                    <p>Bonus RO</p>
                    <p>{data ? <FormatRupiah value={data.bonus_ro}/>:"Rp. 0"}</p>
                </div>
            </div>
            <div className="bg-white shadow-md p-2 w-full border border-gray-200 rounded-lg flex flex-col mb-6">
                <div className="flex justify-between border-b border-gray-200 pb-1 mb-1 text-sm">
                    <p className="text-blue-500">Jumlah Bonus</p>
                    <p className="font-bold text-blue-500"><p>{data ? <FormatRupiah value={data.jml_bonus}/>:"Rp. 0"}</p></p>
                </div>
                <div className="flex justify-between border-b border-gray-200 pb-1 mb-1 text-sm">
                    <p className="text-red-500">Jumlah Penarikan</p>
                    <p className="font-bold text-red-500"><p>{data ? <FormatRupiah value={data.jml_wd}/>:"Rp. 0"}</p></p>
                </div>
                <div className="flex justify-between pb-1 text-sm">
                    <p className="text-green-700">Bonus Tersedia</p>
                    <p className="font-bold text-green-700"><p>{data ? <FormatRupiah value={data.sisa_bonus}/>:"Rp. 0"}</p></p>
                </div>
            </div>
            <div className="flex justify-between mt-3 mb-4">
                <p className="text-sm font-bold">Withdraw</p>
                <p className="text-sm font-bold text-blue-500">Riwayat Withdraw</p>
            </div>
            <div className="mb-2">
                <input type="number" 
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500" 
                    placeholder="Jumlah Withdraw" 
                    required
                    />
            </div>
            <button type="submit" 
                className="w-full text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Submit
            </button>
            <div className="w-full p-2 bg-gray-200 rounded-lg mt-6 text-sky-600 text-sm">
                <p className="text-center mb-3 font-bold">Syarat dan Ketentuan</p>
                <ol className="space-y-1">
                    <li>Minimal jumlah Withdraw sebesar Rp. 100.000</li>
                    <li>Maksimal Proses pentranferan bonus 1 x 24 jam dari tanggal submit</li>
                </ol>
            </div>
        </div>
    )
}
export default Bonus