import { useEffect, useState } from "react";
import useGeneology from "../hook/useGeneology";
import IconBack from "../icon/IconBack";
import DropdownUser from "./genelogy/DropdownUser";

const Geneology = () => {
    const [username, setUsername ]= useState("")
    const { showGeneology,data } = useGeneology();
    useEffect(()=>{
        setUsername(localStorage.getItem("username"))
    },[username])
    
    useEffect(() => {
        showGeneology(username)
    }, [username, showGeneology]);

    return (
        <div>
            <div className="flex bg-white p-4 w-full">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className="w-9 me-3" />
                </div>
                <p className="font-semibold">Geneology</p>
            </div>
           <div className="w-100 bg-white p-2 rounded-lg m-4">
            {data && data.length > 0 ? (
                    data.map((user) => (
                        <DropdownUser key={user.username} user={user} />
                    ))
                ) : (
                    <p>No data available</p>
                )}
           </div>
        </div>
    );
};

export default Geneology;
