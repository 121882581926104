import React, {Suspense,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';

import Home from './components/Home';
import Pages from './components/Pages'
import Login from './pages/Login'

const AUTO_LOGOUT_TIME = 60 * 60 * 1000;

function RequireAuth({ children }) {
  const TOKEN = localStorage.getItem("username");
  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation"];
  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r));

  if (!TOKEN && !isPublicPage) {
    return <Navigate to="/auth" replace />;
  }
  return children;
}


function App() {
  useEffect(() => {
    const checkSession = () => {
      const loginTime = localStorage.getItem('loginTime');
      const now = new Date().getTime(); 
      if (loginTime && now - loginTime > AUTO_LOGOUT_TIME) {
        handleLogout();
      }
    };
    const interval = setInterval(checkSession, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/auth";
  };
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
        <Route path="/auth" element={<Login />} />
          <Route path="/app/*" element={
            <RequireAuth>
              <Home />
            </RequireAuth>
            } />
          <Route path="/pages/*" element={
            <RequireAuth>
              <Pages />
            </RequireAuth>
          } />
          <Route path="*" element={<Navigate to={localStorage.getItem("username") ? "/app/dashboard" : "/auth"} replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
