import { useEffect, useState } from "react"
import useUser from "../hook/useUser"
import IconBack from "../icon/IconBack"
import useUpgrade from "../hook/useUpgrade"
import Loading from "../components/Loading"
const Upgrade = () => {
    const [paket,setPaket] = useState("")
    const [success, setSuccess] = useState(false);
    const [vailed,setVailed] = useState(false);
    const [message,setMessage] = useState("");
    const [loadig,setLoadings] = useState(false)
    const { showUser, data } = useUser()
    const { showPin, data: dataPin } = useUpgrade()
    useEffect(() => {
        // Retrieve user data when component mounts
        const username = localStorage.getItem("username");
        if (username) showUser(username);
    }, [showUser]);

    useEffect(() => {
        // Call showPin only when data is fully loaded and contains the required fields
        if (data && data.username && data.paket) {
            showPin(data.username, data.paket);
        }
    }, [data, showPin]);
    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoadings(true)
        const formData = {
            paket,
            username:localStorage.getItem("username")
          };
          try {
            const response = await fetch("/api/upgrade", {
              method: "POST",
              headers: {
                "x-api-key": "SebaraInvinityInternasional",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            });
      
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
      
            const results = await response.json();
            if(results.code === 200){
                setMessage(results.message)
                setSuccess(true)
            }else{
                setMessage(results.message)
                setVailed(true)
            }
            showUser(localStorage.getItem("username"));
            if (data && data.username && data.paket) {
                showPin(data.username, data.paket);
            }
            setTimeout(()=>{
                setSuccess(false)
                setVailed(false)
            },2000)
          } catch (err) {
            setVailed(err.message || "Something went wrong");
          } finally {
            setLoadings(false);
          }
    }
    if(loadig){
        return(<Loading/>)
    }
    return (
        <div>
            <div className="flex bg-white p-4">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className={`w-9 me-3`} />
                </div>
                <p className="font-semibold">Upgrade</p>
            </div>
            <div className="bg-white m-4 p-3 w-100 border-2 border-gray-100 shadow-md rounded-lg flex flex-col">
                <p className="text-blue-500 font-bold mb-1">Paket Aktif</p>
                <div className="w-full p-2 text-center text-blue-50 font-bold bg-blue-400 border-2 border-blue-200 mb-6 rounded-lg">
                    {data ? data.namaPaket : ""}
                </div>
                {success && (
                    <div className="w-full p-2 bg-blue-300 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                {vailed && (
                    <div className="w-full p-2 bg-red-500 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                {dataPin && dataPin.length > 0 ? (<div className="flex flex-col">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="etxt-semibold mb-1">Jenis Paket <span className="text-red-500">*</span></label>
                            <select
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                                required
                                name="paket"
                                value={paket}
                                onChange={(e) => setPaket(e.target.value)}
                            >
                                <option value={`0`}>Pilih Paket</option>
                                {dataPin &&  dataPin.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <button type="submit"
                            className="w-full text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                            UPgrade
                        </button>
                    </form>
                </div>) : (
                    <div className="w-full p-2 text-center text-blue-50 font-bold bg-red-500 mb-6 rounded-lg">
                        Anda belum memiliki PIN untuk Upgrade
                    </div>
                )}
            </div>
        </div>
    )
}
export default Upgrade