import { useEffect } from "react"
import useSponsoring from "../hook/useSponsoring"
import IconBack from "../icon/IconBack"
const Sponsoring = ()=>{
    const {showData,data} = useSponsoring()
    useEffect(()=>{
        showData()
    },[showData])
    return(
        <div>
            <div className="flex bg-white p-4">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className={`w-9 me-3`}/>
                </div>
                <p className="font-semibold">Sponsoring</p>
            </div>
            <div className="bg-white m-4 p-3 w-100 border-2 border-gray-100 shadow-md rounded-lg flex flex-col overflow-auto">
                {data && data.length > 0 ?(
                    data.map((items,index)=>{
                        return(
                            <div className="flex justify-between mb-2 border-b border-gray-200 pb-2">
                                <div>
                                    <p className="text-xs font-bold">{items.name}</p>
                                    <p className="text-xs text-blue-500">{items.paket}</p>
                                </div>
                                <div className="text-right">
                                    <p className="text-xs">{items.username}</p>
                                    <p className="text-xs">{items.phone}</p>
                                </div>
                            </div>
                        )
                    })
                ):(
                    <div className="w-full p-3 bg-red-500 text-center text-white rounded-lg">
                        Anda belum memiliki sponsor
                    </div>
                )}
            </div>
        </div>
    )
}
export default Sponsoring