import BottomNavigationView from "../components/BottomNavigationView";
import { Route,Routes} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Jaringan from "../pages/Jaringan";
import Market from "../pages/Market";
import Bonus from "../pages/Bonus";
import Profile from "../pages/Profile";
const Home = ()=>{
    return(
        <div className="h-screen w-full bg-gray-200 flex">
        <div className="mx-auto md:w-1/4 w-full bg-gray-100 flex flex-col">
            <div className="w-full p-3 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray scrollbar-thumb-rounded">
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/jaringan" element={<Jaringan/>}/>
                    <Route path="/market" element={<Market/>}/>
                    <Route path="/bonus" element={<Bonus/>}/>
                    <Route path="/profil" element={<Profile/>}/>
                </Routes>
            </div>
            <BottomNavigationView />
        </div>
      </div>
    )
}
export default Home