import React from "react"
import IconAddUser from "../../icon/IconAddUser"

const RegisterUser = ({onClick}) => {
    return (
        <div
            className="flex flex-col cursor-pointer p-2 rounded-lg text-sm border bg-blue-100 border-blue-400 shadow-md mb-2 justify-center items-center"
            onClick={onClick}
        >
            <div className="h-9 w-9 rounded-full bg-blue-200 flex justify-center items-center mb-1">
            <IconAddUser className="w-7 h-7 text-blue-500" />
            </div>
            <div className="w-full py-[1px] px-2 text-xs rounded text-center bg-blue-500 text-white"> Daftar</div>
        </div>
    )
}
export default RegisterUser