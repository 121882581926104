import usePaket from "../hook/usePaket";
import IconBack from "../icon/IconBack"
import { useEffect,useState } from "react";
import Loading from "../components/Loading";
const BeliPin = ()=>{
    const [amount, setAmount] = useState("");
    const [paket, setPaket] = useState("");
    const [success, setSuccess] = useState(false);
    const [vailed,setVailed] = useState(false);
    const [message,setMessage] = useState("");
    const { showPaket, loading, error, data } = usePaket();
    const [loadigs,setLoadings] = useState(false)
    useEffect(()=>{
        showPaket();
    },[showPaket])
    if(loading){
        return(<Loading/>)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true)
        const formData = {
            amount,
            paket,
            username:localStorage.getItem("username")
          };
          try {
            const response = await fetch("/api/beli-pin", {
              method: "POST",
              headers: {
                "x-api-key": "SebaraInvinityInternasional",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            });
      
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
      
            const results = await response.json();
            setMessage(results.statusMessage)
            setSuccess(true)
            window.location.href = results.paymentUrl
            setAmount("");
            setPaket("0");
            setTimeout(()=>{
                setSuccess(false)
                setVailed(false)
            },2000)
          } catch (err) {
            setVailed(err.message || "Something went wrong");
          } finally {
            setLoadings(false);
          }
    }
    if(loadigs){
        return (<Loading/>)
    }
    return(
        <div className="flex flex-col">
            <div className="flex bg-white p-4">
                <div onClick={() => window.history.go(-1)}>
                    <IconBack className={`w-9 me-3 cursor-pointer`}/>
                </div>
                <p className="font-semibold">Pembelian PIN Aktivasi</p>
            </div>
            <div className="bg-white m-4 p-3 w-100 border-2 border-gray-100 shadow-md rounded-lg flex flex-col">
                {success && (
                    <div className="w-full p-2 bg-blue-300 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                {vailed && (
                    <div className="w-full p-2 bg-red-500 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="etxt-semibold mb-1">Jumlah PIN <span className="text-red-500">*</span></label>
                    <input type="number"
                        name="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500" 
                        placeholder="0" 
                        required
                        />
                </div>
                <div className="mb-4">
                    <label className="etxt-semibold mb-1">Jenis Paket <span className="text-red-500">*</span></label>
                    <select 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        required
                        name="paket"
                        value={paket}
                        onChange={(e) => setPaket(e.target.value)}
                        >
                        <option value={`0`}>Pilih Paket</option>
                        {!error && data && data.map((item,index)=>(
                            <option key={index} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" 
                    className="w-full mt-5 text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                        Submit
                </button>
                </form>
            </div>
        </div>
    )
}
export default BeliPin