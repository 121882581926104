
import { useEffect } from "react"
import useBonus from "../hook/useBonus"
import IconBack from "../icon/IconBack"
import { FormatRupiah } from "@arismun/format-rupiah";
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
const Bonus = () => {
    const {showBonus,data} = useBonus()
    useEffect(()=>{
        showBonus()
    },[showBonus,data])
    return (
        <div>
            <div className="flex bg-white p-4">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className={`w-9 me-3`} />
                </div>
                <p className="font-semibold">Laporan Bonus</p>
            </div>
            <div className="bg-white m-4 p-3 w-100 border-2 border-gray-100 shadow-md rounded-lg flex flex-col">
               {data && data.length > 0 ?
                    data.map((items,index)=>{
                        return(
                            <div className="flex justify-between items-center border-b border-gray-200 pb-2 mb-2">
                                <div>
                                    <p className="text-xs font-bold">{items.type}</p>
                                    <p className="text-xs">{format(new Date(items.created_at), "dd MMMM yyyy HH:ii:ss", { locale: id })}</p>
                                </div>
                                <div className="font-bold text-sm">
                                    {<FormatRupiah value={items.amount}/>}
                                </div>
                            </div>
                        )
                    })
                    :(<div className="w-full p-2 bg-red-500 text-white text-center rounded-lg">
                        Anda belum memiliki bonus
                    </div>)
               }
            </div>
        </div>
    )
}
export default Bonus