import { useState, useEffect } from "react";
import IconDown from "../../icon/iconDown"; // Pastikan ikon ini ada
import useGeneology from "../../hook/useGeneology";

const DropdownUser = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [downlineData, setDownlineData] = useState([]);
    const { showGeneology } = useGeneology();

    // Ambil downline saat komponen pertama kali dirender
    useEffect(() => {
        const fetchDownline = async () => {
            const downline = await showGeneology(user.username); // Ambil data downline
            setDownlineData(downline || []); // Simpan data downline
        };
        
        fetchDownline(); // Panggil fungsi untuk fetch downline
    }, [user.username, showGeneology]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen); // Toggle dropdown
    };

    return (
        <div className="flex flex-col space-y-1">
            <div className="flex justify-between cursor-pointer border-b border-gray-300 pb-1" onClick={toggleDropdown}>
                <div className="flex items-center">
                    <p className="text-sm font-bold mr-2">{user.name}</p> /
                    <p className="text-sm ms-2 text-blue-500">{user.posisi}</p>
                </div>
                <div>
                    {downlineData.length > 0 && ( // Cek jika user memiliki downline
                        <IconDown className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} />
                    )}
                </div>
            </div>
            {isOpen && downlineData.length > 0 && (
                <div className="downline-list">
                    {downlineData.map((downlineUser) => (
                        <DropdownUser key={downlineUser.username} user={downlineUser} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownUser;
