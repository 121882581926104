import React from "react"
import IconRight from "../icon/IconRight"
import IconUser from "../icon/IconUser"
import { useState,useEffect } from "react"
import useUser from "../hook/useUser"
import Loading from "../components/Loading"
const Profile = ()=>{
    const [username, setUsername] = useState("");
    const { showUser, loading, error, data } = useUser();

    useEffect(() => {
        setUsername(localStorage.getItem("username"));
      }, []);
    useEffect(()=>{
        showUser(username);
    },[username,showUser])

    const Logout = ()=>{
        localStorage.clear();
        window.location.href = "/"
    }
    const openProfile = ()=>{
        window.location.href = "../pages/profile"
    }
    const openUpgrade = ()=>{
        window.location.href = "../pages/upgrade"
    }
    const openBonus = ()=>{
        window.location.href = "../pages/bonus"
    }
    const openReward = ()=>{
        window.location.href = "../pages/reward"
    }
    if(loading){
        return(<Loading/>)
    }
    if(error){
        return(
            <div className="w-full p-3 bg-red-500 rounded-lg text-center">
                {error}
            </div>
        )
    }
    return(
        <div>
            <div className="bg-white shadow-md p-2 w-full border border-gray-200 rounded-lg flex flex-col mb-6">
                <div className="flex text-sm items-center">
                    <div className="w-9 h-9 bg-blue-200 rounded-full flex justify-center items-center">
                        <IconUser className={`h-7 w-7 text-blue-500`}/>
                    </div>
                    <div className="block ml-3">
                        <p className="text-sm font-bold">{data ? data.name :"Loading.."}</p>
                        <p className="text-xs">{data ? data.email :"Loading.."}</p>
                    </div>
                </div>
            </div>
            <div className="w-full p-2 text-center text-yellow-900 font-bold bg-amber-500 border-2 border-amber-400 mb-6 rounded-lg">
                {data ? data.namaPaket : ""}
            </div>
            <div 
                className="flex justify-between items-center pb-3 mb-3 border-b-[1px] border-gray-300 cursor-pointer"
                onClick={openProfile}
                >
                <p className="text-sm">Update Profil</p>
                <IconRight className={`text-blue-500`} />
            </div>
            <div 
                className="flex justify-between items-center pb-3 mb-3 border-b-[1px] border-gray-300 cursor-pointer" 
                onClick={openUpgrade}
                >
                <p className="text-sm">Upgrade</p>
                <IconRight className={`text-blue-500`} />
            </div>
            <div className="flex justify-between items-center pb-3 mb-3 border-b-[1px] border-gray-300 cursor-pointer"
                onClick={openBonus}
                >
                <p className="text-sm">Laporan Bonus</p>
                <IconRight className={`text-blue-500`} />
            </div>
            <div className="flex justify-between items-center pb-3 mb-3 border-b-[1px] border-gray-300 cursor-pointer" onClick={openReward}>
                <p className="text-sm">Reward</p>
                <IconRight className={`text-blue-500`} />
            </div>
            <div className="w-full bg-red-500 rouded-lg p-2 text-white text-center rounded-lg mt-16 cursor-pointer" onClick={()=>Logout()}>
                Keluar
            </div>
        </div>
    )
}
export default Profile