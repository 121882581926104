import { useEffect } from "react"
import useReward from "../hook/useReward"
import IconBack from "../icon/IconBack"
import useUser from "../hook/useUser"
const Reward = () => {
    const {showReward,data}= useReward()
    const {showUser,data:dataUser} = useUser()
    useEffect(()=>{
        showReward()
    },[showReward,data])
    useEffect(()=>{
        showUser(localStorage.getItem("username"))
    },[showUser,dataUser])
    return (
        <div>
            <div className="flex bg-white p-4">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className={`w-9 me-3`} />
                </div>
                <p className="font-semibold">Reward</p>
            </div>
            <div className="grid grid-cols-2 gap-5">
                <div className="p-3 mt-4 mx-4 bg-green-200 rounded-lg text-center">
                    <p className="text-xs">Kaki Kiri</p>
                    <p className="font-bold">{dataUser && dataUser.count_left}</p>
                </div>
                <div className="p-3 mt-4 mx-4 bg-yellow-200 rounded-lg text-center">
                    <p className="text-xs">Kaki Kanan</p>
                    <p className="font-bold">{dataUser && dataUser.count_right}</p>
                </div>
            </div>
            {data && data.map((items,index)=>(
                <div key={index} className="bg-white m-4 p-3 w-100 border-2 border-gray-100 shadow-md rounded-lg flex flex-col">
                    <div className="flex justify-between items-center">
                        <div className="w-1/3">{items.name}</div>
                        <div className="w-1/3 text-center">{items.left} | {items.right}</div>
                        <div className="w-1/3">{dataUser && dataUser.count_left === items.left && dataUser.count_right === items.right ? (
                            <div className="bg-green-600 text-white text-center text-xs px-3 py-[1.5px] rounded-full cursor-pointer">
                                Pending
                            </div>
                        ) : (
                            <div className="bg-gray-300 text-white text-center text-xs px-3 py-[1.5px] rounded-full">
                                Pending
                            </div>
                        )
                            }</div>
                    </div>
                </div>
            ))}
            
        </div>
    )
}
export default Reward