import React, { useState, useEffect } from "react"
import IconClose from "../../icon/IconClose"
import useMyPin from "../../hook/useMyPin"
import Loading from "../../components/Loading"
const FormRegister = ({ onClick, upline, posisi }) => {
    const [name, setName] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [pinData, setPinData] = useState([]);
    const [users, setUsers] = useState("");
    const [sponsor, setSponsor] = useState("");
    const [selectedPin, setSelectedPin] = useState("");
    const [success, setSuccess] = useState(false);
    const [vailed, setVailed] = useState(false);
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState("");
    const [loadigs, setLoadings] = useState(false)

    const handleChange = (event) => {
        setSelectedPin(event.target.value);
    };
    const { showPin, data } = useMyPin();

    useEffect(() => {
        setUsers(localStorage.getItem("username"));
        setSponsor(localStorage.getItem("username"))
    }, [users, sponsor]);
    
    useEffect(() => {
        showPin(users);
    }, [users, showPin])

    useEffect(() => {
        if (data) {
            const statusZeroData = data.filter(item => item.status === 0);
            setPinData(statusZeroData);
        }
    }, [data]);
    const SubmitForm = async (e) => {
        e.preventDefault();
        setLoadings(true)
        const formData = {
            upline,posisi,username,name,password,selectedPin,phone,sponsor
        };
        try {
            const response = await fetch("/api/daftar", {
                method: "POST",
                headers: {
                    "x-api-key": "SebaraInvinityInternasional",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const results = await response.json();
            console.log(results)
            if (results.code === 200) {
                setMessage(results.message)
                setSuccess(true)
                onClick()
            } else {
                setMessage(results.message)
                setVailed(true)
            }
            setUsername("");
            setPassword("");
            setName("")
        } catch (err) {
            setVailed(err.message || "Something went wrong");
        } finally {
            setLoadings(false);
        }
    }
    if(loadigs){
        return (<Loading/>)
    }
    return (
        <div className={`bg-white w-100 p-3 m-4 rounded-lg `}>
            <div className="flex justify-between mb-4 border-b border-gray-200 pb-3">
                <p>Form Pendaftaran</p>
                <div onClick={onClick}><IconClose className={`w-6 h-6 text-red-300 cursor-pointer`} /></div>
            </div>
            {success && (
                <div className="w-full p-2 bg-blue-300 rounded-lg text-center text-white mb-6">{message}</div>
            )}
            {vailed && (
                <div className="w-full p-2 bg-red-500 rounded-lg text-center text-white mb-6">{message}</div>
            )}
            <div className="flex justify-between mb-2">
                <p>Upline</p>
                <p>{upline}</p>
            </div>
            <div className="flex justify-between mb-2">
                <p>Sponsor</p>
                <p>{`sebara`}</p>
            </div>
            <div className="flex justify-between mb-6">
                <p>Posisi</p>
                <p>{posisi}</p>
            </div>
            <form onSubmit={(e)=>SubmitForm(e)}>
                <div className="mb-2">
                    <label className="etxt-semibold mb-1">Paket Aktivasi <span className="text-red-500">*</span></label>
                    <select
                        value={selectedPin}
                        onChange={handleChange}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        required
                    >
                        <option value={0}>Pilih Paket</option>
                        {pinData.map((pin, index) => (
                            <option key={index} value={pin.id}>{pin.name}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-2">
                    <label className="etxt-semibold mb-1">Nama Lengkap <span className="text-red-500">*</span></label>
                    <input type="text"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        placeholder="Nama User"
                        required
                    />
                </div>
                <div className="mb-2">
                    <label className="etxt-semibold mb-1">No. Handphone <span className="text-red-500">*</span></label>
                    <input type="number"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        placeholder="628123456789"
                        required
                    />
                </div>
                <div className="mb-2">
                    <label className="etxt-semibold mb-1">Username <span className="text-red-500">*</span></label>
                    <input type="text"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        placeholder="UserName"
                        required
                    />
                </div>
                <div className="mb-2">
                    <label className="etxt-semibold mb-1">Password <span className="text-red-500">*</span></label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        placeholder="Password"
                        required
                    />
                </div>
                <button type="submit"
                    className="w-full mt-2 text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Submit
                </button>
            </form>
        </div>
    )
}
export default FormRegister