import { useState } from 'react';
import logo from '../logo_sabara.png'
import Loading from '../components/Loading';

const Login = () => {
    
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [success, setSuccess] = useState(false);
    const [vailed, setVailed] = useState(false);
    const [message, setMessage] = useState("");
    const [loadigs, setLoadings] = useState(false)
    const ActionLogin = async (e) => {
        e.preventDefault();
        setLoadings(true)
        const formData = {
            username, password
        };
        try {
            const response = await fetch("/api/login", {
                method: "POST",
                headers: {
                    "x-api-key": "SebaraInvinityInternasional",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const results = await response.json();
            if (results.code === 200) {
                setMessage(results.message)
                setSuccess(true)
                const now = new Date().getTime();
                localStorage.setItem('loginTime', now);
                localStorage.setItem("username",formData.username)
                window.location.href = "/";
            } else {
                setMessage(results.message)
                setVailed(true)
            }
            setUsername("");
            setPassword("");
            setTimeout(() => {
                setSuccess(false)
                setVailed(false)
            }, 2000)
        } catch (err) {
            setVailed(err.message || "Something went wrong");
        } finally {
            setLoadings(false);
        }
    }
    return (
        <div className="h-screen w-full bg-gray-200 flex">
            <div className="mx-auto md:w-1/4 w-full bg-gray-100 flex flex-col justify-center items-center">
                <div className="flex flex-col w-full p-3">
                    <div className="flex mx-auto mb-4">
                        <img src={logo} className='w-12 mr-3' alt='Sebara Invinity' />
                        <div className='flex flex-col'>
                            <p className='text-xl font-bold text-blue-500'>Sebara Invinity</p>
                            <p className='text-blue-600 font-bold'>Internasional</p>
                        </div>
                    </div>
                    <div className="bg-white p-4 flex flex-col rounded-lg">
                        <p className='text-center text-xl font-bold mb-4'>Login User</p>
                        {success && (
                            <div className="w-full p-2 bg-green-500 rounded-lg text-center text-white mb-6">{message}</div>
                        )}
                        {vailed && (
                            <div className="w-full p-2 bg-red-500 rounded-lg text-center text-white mb-6">{message}</div>
                        )}
                        {loadigs ?
                            (<Loading />)
                            :
                            (
                                <form onSubmit={(e) => ActionLogin(e)}>
                                    <div className="mb-4">
                                        <label className="etxt-semibold mb-1">Username <span className="text-red-500">*</span></label>
                                        <input type="text"
                                            name="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                                            placeholder="Username"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="etxt-semibold mb-1">Username <span className="text-red-500">*</span></label>
                                        <input type="password"
                                            name="username"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                                            placeholder="********"
                                            required
                                        />
                                    </div>
                                    <button type="submit"
                                        className="w-full mt-5 text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                                        Sign In
                                    </button>
                                </form>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login