import useMyPin from "../hook/useMyPin";
import IconBack from "../icon/IconBack"
import { useState,useEffect } from "react";
import { format } from 'date-fns';
import { id } from 'date-fns/locale';

const RiwayatPin = ()=>{
    const [username, setUsername] = useState("");
    const { showPin, loading, error, data } = useMyPin();
    useEffect(() => {
        setUsername(localStorage.getItem("username"));
    }, []);
    useEffect(() => {
        showPin(username);
    }, [username, showPin])
    return(
        <div>
            <div className="flex bg-white p-4">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className={`w-9 me-3`}/>
                </div>
                <p className="font-semibold">Riwayat PIN Aktivasi</p>
            </div>
            <div className="bg-white m-4 p-3 w-100 border-2 border-gray-100 shadow-md rounded-lg flex flex-col">
                {!loading && !error && data && data.length > 0 ?(
                    data.map((item,index)=>{
                        const dates = format(new Date(item.created_at), "dd MMMM yyyy", { locale: id })
                        let status
                        switch (item.status) {
                            case 0:
                                status = <div className="text-green-600 text-sm font-bold">Aktif</div>                                
                                break;
                            case 1:
                                status = <div className="text-red-600 text-sm font-bold">Terpakai</div>                                
                                break;
                            case 2:
                                    status = <div className="text-orange-600 text-sm font-bold">Dikirim</div>                                
                                    break;
                            default:
                                break;
                        }
                        return (
                            <div key={index} className="flex justify-between border-b-[1px] pb-2 mb-2 border-gray-300">
                                <div>
                                    <p className="text-sm font-semibold">{item.name}</p>
                                    <p className="text-xs">{item.keterangan}</p>
                                </div>
                                <div className="text-right">
                                {status}
                                <p className="text-xs">{dates}</p>
                                </div>
                            </div>
                        )
                    })
                ):(
                    <div className="w-full bg-red-500 text-center text-white p-3 rounded-lg">
                        Anda belum memiliki PIN Aktivasi
                    </div>
                )}
            </div>
        </div>
    )
}
export default RiwayatPin