import React from "react";
import { Route,Routes} from "react-router-dom";
import BeliPin from "../interfaces/BeliPin";
import RiwayatPin from "../interfaces/RiwayatPin";
import TransferPin from "../interfaces/TransferPin";
import Sponsoring from "../interfaces/Sponsoring";
import Geneology from "../interfaces/Geneology";
import Register from "../interfaces/Register";
import Profile from "../interfaces/Profile";
import Upgrade from "../interfaces/Upgrade";
import Bonus from "../interfaces/Bonus";
import Reward from "../interfaces/Reward";
const Home = ()=>{
    return(
        <div className="h-screen flex w-full bg-gray-200 flex">
        <div className="mx-auto md:w-1/4 w-full bg-gray-100 flex flex-col">
            <div className="w-full flex flex-col overflow-y-scroll scrollbar-thin scrollbar-thumb-gray scrollbar-thumb-rounded">
                <Routes>
                    <Route path="/beli-pin" element={<BeliPin />} />
                    <Route path="/riwayat-pin" element={<RiwayatPin />} />
                    <Route path="/transfer-pin" element={<TransferPin />} />
                    <Route path="/sponsoring" element={<Sponsoring />} />
                    <Route path="/geneology" element={<Geneology />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/upgrade" element={<Upgrade />} />
                    <Route path="/bonus" element={<Bonus />} />
                    <Route path="/reward" element={<Reward />} />
                </Routes>
            </div>
        </div>
      </div>
    )
}
export default Home