import React from "react";
import { useEffect, useState } from "react";
import useUser from "../hook/useUser";
import Loading from "../components/Loading"
import { FormatRupiah } from "@arismun/format-rupiah";
import useUserToday from "../hook/useUserToday";
const Dashboard = () => {
    const [username, setUsername] = useState("");
    const { showUser, loading, error, data } = useUser();
    const {userToday,loading:loadingToday,error:errorToday,data:dataToday} = useUserToday()
    useEffect(() => {
        setUsername(localStorage.getItem("username"));
      }, []);
    useEffect(()=>{
        showUser(username);
        userToday()
    },[username,showUser,userToday])
    if(loading){
        return(<Loading/>)
    }
    if(error){
        return(
            <div className="w-full p-3 bg-red-500 rounded-lg text-center">
                {error}
            </div>
        )
    }
    return (
     <div className="w-full flex flex-col">
        <p className="text-md">Selamat Datang</p>
        <p className="text-blue-500 font-bold mb-6">{data ? data.name : "Loading..."}</p>
        <div className="w-full grid grid-cols-3 gap-3 mb-6">
            <div className="bg-blue-100 px-2 py-1 w-full border-2 border-blue-200 rounded-lg flex flex-col justify-center items-center">
                <p className="text-sm text-blue-800">Kaki Kiri</p>
                <p className="text-blue-800 font-bold">{data ? data.count_left : 0}</p>
            </div>
            <div className="bg-green-100 px-2 py-1 w-full border-2 border-green-200 rounded-lg flex flex-col justify-center items-center">
                <p className="text-sm text-green-800">Sponsoring</p>
                <p className="text-green-800 font-bold">{data ? data.sponsoring : 0}</p>
            </div>
            <div className="bg-orange-100 px-2 py-1 w-full border-2 border-orange-200 rounded-lg flex flex-col justify-center items-center">
                <p className="text-sm text-orange-800">Kaki Kiri</p>
                <p className="text-orange-800 font-bold">{data ? data.count_right : 0}</p>
            </div>
        </div>
        <div className="bg-white shadow-md px-2 py-1 w-full border border-gray-200 rounded-lg flex flex-col mb-6">
            <p className="text-center font-bold mb-2">Rekap Bonus</p>
            <div className="flex justify-between border-b border-gray-200 pb-1 mb-1 text-sm">
                <p className="text-blue-500">Jumlah Bonus</p>
                <p className="font-bold text-blue-500"><FormatRupiah value={data ? data.jml_bonus : 0}/></p>
            </div>
            <div className="flex justify-between border-b border-gray-200 pb-1 mb-1 text-sm">
                <p className="text-red-500">Jumlah Penarikan</p>
                <p className="font-bold text-red-500"><FormatRupiah value={data ? data.jml_wd : 0}/></p>
            </div>
            <div className="flex justify-between pb-1 text-sm">
                <p className="text-green-700">Bonus Tersedia</p>
                <p className="font-bold text-green-700"><FormatRupiah value={data ? data.sisa_bonus : 0}/></p>
            </div>
        </div>
        <div className="bg-white shadow-md px-2 py-1 w-full border border-gray-200 rounded-lg flex flex-col mb-6">
            <p className="text-center font-bold mb-2">PIN Aktivasi</p>
            <div className="flex justify-between border-b border-gray-200 pb-1 mb-1 text-sm">
                <p className="text-blue-500">Total Pin</p>
                <p className="font-bold text-blue-500">{data ? data.pin_all : 0}</p>
            </div>
            <div className="flex justify-between border-b border-gray-200 pb-1 mb-1 text-sm">
                <p className="text-red-500">Pin Terpakai</p>
                <p className="font-bold text-red-500">{data ? data.pin_unactive : 0}</p>
            </div>
            <div className="flex justify-between pb-1 text-sm">
                <p className="text-green-700">Pin Tersedia</p>
                <p className="font-bold text-green-700">{data ? data.pin_active : 0}</p>
            </div>
        </div>
        <div className="bg-white shadow-md px-2 py-1 w-full border border-gray-200 rounded-lg flex flex-col mb-6">
            <p className="text-center font-bold mb-2">Member Hari Ini</p>
            {loadingToday &&(
                <Loading/>
            )}
            {!errorToday && dataToday && dataToday.length > 0 ? (
                <>
                    {dataToday.map((item, index) => (
                    <div key={index} className="flex justify-between border-b border-gray-200 pb-1 mb-1 text-sm">
                        <div className="text-sm">
                        <p>{item.name}</p>
                        <p className="font-bold">{item.username}</p>
                        </div>
                        <div className="text-sm text-right">
                        <p className="text-yellow-600 font-bold">{item.namaPaket}</p>
                        <p className="">{item.email}</p>
                        </div>
                    </div>
                    ))}
                </>
                ):(
                    <div className="w-full p-2 rounded-lg text-center bg-sky-200 text-blue-600 font-bold">
                        Member baru belum ditemukan
                    </div>
                )}
        </div>
     </div>
    );
};

export default Dashboard;

  