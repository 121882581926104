import React, { useEffect, useState } from "react";
import IconBack from "../icon/IconBack";
import useUser from "../hook/useUser";
import Loading from "../components/Loading";


const Profile = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [success, setSuccess] = useState(false);
    const [vailed,setVailed] = useState(false);
    const [message,setMessage] = useState("");
    const [loadig,setLoadings] = useState(false)

    const { showUser, data } = useUser()

    useEffect(() => {
        showUser(localStorage.getItem("username"))
    }, [showUser])

    useEffect(() => {
        if (data) {
            setUsername(data.username)
            setName(data.name)
            setEmail(data.email)
            setPhone(data.phone)
        }
    }, [data])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true)
        const formData = {
            username,password,name,email,phone
          };
          try {
            const response = await fetch("/api/update-profile", {
              method: "POST",
              headers: {
                "x-api-key": "SebaraInvinityInternasional",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            });
      
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
      
            const results = await response.json();
            if(results.code === 200){
                setMessage(results.message)
                setSuccess(true)
            }else{
                setMessage(results.message)
                setVailed(true)
            }
            showUser(localStorage.getItem("username"))
            setTimeout(()=>{
                setSuccess(false)
                setVailed(false)
            },2000)
          } catch (err) {
            setVailed(err.message || "Something went wrong");
          } finally {
            setLoadings(false);
          }
    }
    if(loadig){
        return(<Loading/>)
    }
    return (
        <div>
            <div className="flex bg-white p-4 w-full">
                <div onClick={() => window.history.go(-1)} className="cursor-pointer">
                    <IconBack className="w-9 me-3" />
                </div>
                <p className="font-semibold">Update Profil</p>
            </div>
            <div className="w-100 bg-white p-2 rounded-lg m-4">
                {success && (
                    <div className="w-full p-2 bg-blue-300 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                {vailed && (
                    <div className="w-full p-2 bg-red-500 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="etxt-semibold mb-1">Username <span className="text-red-500">*</span></label>
                        <input type="text"
                            name="receiver"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                            placeholder="Username Penerima"
                            required
                            readOnly
                        />
                    </div>
                    <div className="mb-4">
                        <label className="etxt-semibold mb-1">Password</label>
                        <input type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                            placeholder="******"

                        />
                        <p className="text-xs text-gray-400">Ketika password masih tetap sama, maka biarkan kosong, ketika anda mau merubah password silahkan di isi</p>
                    </div>
                    <div className="mb-4">
                        <label className="etxt-semibold mb-1">Nama Lengkap <span className="text-red-500">*</span></label>
                        <input type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                            placeholder="Nama Lengkap"

                        />
                    </div>
                    <div className="mb-4">
                        <label className="etxt-semibold mb-1">Email <span className="text-red-500">*</span></label>
                        <input type="email"
                            name="setEmail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                            placeholder="email@sebara.com"

                        />
                    </div>
                    <div className="mb-4">
                        <label className="etxt-semibold mb-1">No. Handphone <span className="text-red-500">*</span></label>
                        <input type="number"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                            placeholder="******"

                        />
                    </div>
                    <button type="submit"
                        className="w-full mt-5 text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                        Update
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Profile;
