import { useEffect, useState } from "react";
import IconBack from "../icon/IconBack";
import IconUser from "../icon/IconUser";
import useRegister from "../hook/useRegister";
import FormRegister from "./register/FormRegister";
import RegisterUser from "./register/RegisterUser";
import DisableReg from "./register/DisableReg";
import BoxUser from "./register/BoxUser";

const Register = () => {
    const [username, setUsername] = useState("");
    const [root, setRoot] = useState("");
    const [kiri, setKiri] = useState("");
    const [kiri_kiri, setKiriKiri] = useState("");
    const [kiri_kanan, setKiriKanan] = useState("");
    const [kanan_kiri, setKananKiri] = useState("");
    const [kanan_kanan, setKananKanan] = useState("");
    const [kanan, setKanan] = useState("");
    const [upline, setUpline] = useState("");
    const [form, setForm] = useState(false);
    const [posisi, setPosisi] = useState(false);
    const { showUser, data } = useRegister();
    
    useEffect(() => {
        setUsername(localStorage.getItem("username"));
    }, []); 

    useEffect(() => {
        showUser(username);
    }, [showUser, username]);

    useEffect(() => {
        if (data) {
            setRoot(data.root);
            setKiri(data.kiri);
            setKanan(data.kanan);
            setKiriKiri(data.kiri_kiri)
            setKiriKanan(data.kiri_kanan)
            setKananKiri(data.kanan_kiri)
            setKananKanan(data.kanan_kanan)
        }
    }, [data]);  // Only depend on 'data'

    const RegisteredUser = (username, position) => {
        setUpline(username);
        setPosisi(position);
        setForm(true);
    };

    const CloseReg = () => {
        setUpline("");
        setForm(false);
        setUsername(localStorage.getItem("username"));
        showUser(username);
    };
    return (
        <>
            {/* Header */}
            <div className="flex bg-white p-4 justify-between">
                <div className="flex">
                    <div onClick={() => window.history.back()} className="cursor-pointer">
                        <IconBack className="w-9 me-3" />
                    </div>
                    <p className="font-semibold">Register</p>
                </div>
                {username !== localStorage.getItem("username") && (
                    <div className="cursor-pointer px-3 py-1 bg-yellow-600 text-white rounded-full text-xs" onClick={()=>setUsername(localStorage.getItem("username"))}>
                        Kembali
                    </div>
                )}
            </div>
            {form && (
                <FormRegister onClick={()=>CloseReg()} upline={upline} posisi={posisi} />
            )}
            <div className={`flex flex-col items-center min-h-screen mt-4 ${form ? 'hidden' : ""}`}>
                <div className="w-full max-w-xs">
                    {/* Root Node */}
                    <div className="flex flex-col items-center">
                        <div
                            className="flex flex-col p-2 cursor-pointer rounded-lg text-sm border bg-green-100 border-green-400 shadow-md mb-4 justify-center items-center"
                        >
                            <div className="h-9 w-9 rounded-full bg-green-200 flex justify-center items-center mb-1">
                                <IconUser className="w-7 h-7 text-green-500" />
                            </div>
                            <span className="text-xs font-bold">
                                {root ? root.username : ""}
                            </span>
                            <span className="text-xs">{root ? root.count_left : 0} | {root ? root.count_right : 0}</span>
                        </div>
                    </div>

                    {/* Level 1 Nodes */}
                    <div className="flex justify-between">
                        {/* Left Node */}
                        <div className="flex flex-col items-center w-1/2">
                            {kiri ? (
                                <BoxUser 
                                    username={kiri.username} 
                                    left={kiri.count_left} 
                                    right={kiri.count_right}
                                    onClick={()=>setUsername(kiri.username)}
                                />
                            ) : (
                                <RegisterUser onClick={() => RegisteredUser(root.username, "kiri")} />
                            )}
                        </div>

                        {/* Right Node */}
                        <div className="flex flex-col items-center w-1/2">
                            {kanan ? (
                                <BoxUser 
                                    username={kanan.username} 
                                    left={kanan.count_left} 
                                    right={kanan.count_right}
                                    onClick={()=>setUsername(kanan.username)}
                                />
                            ) : (
                                <RegisterUser onClick={() => RegisteredUser(root.username, "kanan")} />
                            )}
                        </div>
                    </div>

                    {/* Level 2 Nodes */}
                    <div className="flex justify-between mt-2">
                        {/* Left Subtree */}
                        <div className="w-1/2 flex justify-between">
                            {/* Left-Left Node */}
                            <div className="flex flex-col items-center w-1/2">
                                {kiri ? 
                                    kiri_kiri ? (
                                        <BoxUser 
                                            username={kiri_kiri.username} 
                                            left={kiri_kiri.count_left} 
                                            right={kiri_kiri.count_right}
                                            onClick={()=>setUsername(kiri_kiri.username)}
                                        />
                                    ):(
                                        <RegisterUser onClick={() => RegisteredUser(kiri.username, "kiri")} />
                                    )
                                    
                                :(
                                    <DisableReg/>
                                )}
                            </div>

                            {/* Left-Right Node */}
                            <div className="flex flex-col items-center w-1/2">
                                {kiri ? kiri_kanan ? (
                                        <BoxUser 
                                            username={kiri_kanan.username} 
                                            left={kiri_kanan.count_left} 
                                            right={kiri_kanan.count_right}
                                            onClick={()=>setUsername(kiri_kanan.username)}
                                        />
                                    ):(
                                        <RegisterUser onClick={() => RegisteredUser(kiri.username, "kiri")} />
                                    ):(
                                    <DisableReg/>
                                )}
                            </div>
                        </div>

                        {/* Right Subtree */}
                        <div className="w-1/2 flex justify-between">
                            {/* Right-Left Node */}
                            <div className="flex flex-col items-center w-1/2">
                                {kanan ? kanan_kiri ? (
                                        <BoxUser 
                                            username={kanan_kiri.username} 
                                            left={kanan_kiri.count_left} 
                                            right={kanan_kiri.count_right}
                                            onClick={()=>setUsername(kanan_kiri.username)}
                                        />
                                    ):(
                                        <RegisterUser onClick={() => RegisteredUser(kiri.username, "kiri")} />
                                    ):(
                                    <DisableReg/>
                                )}
                            </div>

                            {/* Right-Right Node */}
                            <div className="flex flex-col items-center w-1/2">
                                {kanan ? kanan_kanan ? (
                                        <BoxUser 
                                            username={kanan_kanan.username} 
                                            left={kanan_kanan.count_left} 
                                            right={kanan_kanan.count_right}
                                            onClick={()=>setUsername(kanan_kanan.username)}
                                        />
                                    ):(
                                        <RegisterUser onClick={() => RegisteredUser(kiri.username, "kiri")} />
                                    ):(
                                    <DisableReg/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
