import { useState, useCallback } from "react";

const useReward = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const showReward = useCallback(async () => {
    setLoading(true);
    setError(null);

    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "SebaraInvinityInternasional");
    myHeaders.append("Content-Type", "application/json");
    
    const raw = "";
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("/api/reward", requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, []); // Fungsi ini akan tetap stabil dan tidak berubah di setiap render

  return { showReward, loading, error, data };
};

export default useReward;
