import { useState, useCallback } from "react";

const useMyPin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const showPin = useCallback(async (username) => {
    setLoading(true);
    setError(null);

    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "SebaraInvinityInternasional");
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({ username });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("/api/my-pin", requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();

      if(result.code === 200){
        setData(result.data);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, []); // Fungsi ini akan tetap stabil dan tidak berubah di setiap render

  return { showPin, loading, error, data };
};

export default useMyPin;
