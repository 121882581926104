import IconBuy from "../icon/IconBuy"
import IconGroup from "../icon/IconGroup"
import IconHome from "../icon/IconHome"
import IconUser from "../icon/IconUser"
import IconWallet from "../icon/IconWallet"
import Menu from "./Menu"

const BottomNavigationView = ()=>{
    return(
        <div className="fixed bottom-0 z-50 md:w-1/4 w-full bg-white grid grid-cols-5 py-3">
            <Menu url={'/app/dashboard'} label={`Home`} Icon={IconHome} />
            <Menu url={`/app/jaringan`} label={`Jaringan`} Icon={IconGroup}/>
            <Menu url={`/app/market`} label={`Market`} Icon={IconBuy}/>
            <Menu url={`/app/bonus`} label={`Bonus`} Icon={IconWallet}/>
            <Menu url={`/app/profil`} label={`Profil`} Icon={IconUser}/>
        </div>
    )
}
export default BottomNavigationView