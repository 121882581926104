import React from "react";
import IconAddUser from "../icon/IconAddUser"
import IconBuy from "../icon/IconBuy"
import IconGroup from "../icon/IconGroup"
import IconHistory from "../icon/IconHistory"
import IconSponsor from "../icon/IconSponsor"
import IconTransfer from "../icon/IconTransfer"
import useUser from "../hook/useUser"
import { useEffect, useState } from "react"
import Loading from "../components/Loading"
import { Link } from "react-router-dom";
import usePaket from "../hook/usePaket";

const Jaringan = () => {
    const [username, setUsername] = useState("");
    const { showUser, loading, error, data } = useUser();
    const [amount, setAmount] = useState("");
    const [paket, setPaket] = useState("");
    const [success, setSuccess] = useState(false);
    const [vailed,setVailed] = useState(false);
    const [message,setMessage] = useState("");
    const { showPaket, error:paketError, data:paketData } = usePaket();
    const [loadigs,setLoadings] = useState(false)
    useEffect(() => {
        setUsername(localStorage.getItem("username"));
    }, []);
    useEffect(() => {
        showUser(username);
        showPaket()
    }, [username, showUser,showPaket])
    if (loading) {
        return (<Loading />)
    }
    if (loadigs) {
        return (<Loading />)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadings(true)
        const formData = {
            amount,
            paket,
            username:"sebara"
          };
          try {
            const response = await fetch("/api/genrate-pin", {
              method: "POST",
              headers: {
                "x-api-key": "SebaraInvinityInternasional",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            });
      
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
      
            const results = await response.json();
            if(results.code === 200){
                setMessage(results.message)
                setSuccess(true)
            }else{
                setMessage(results.message)
                setVailed(true)
            }
            setAmount("");
            setPaket("0");
            showUser(username);
            setTimeout(()=>{
                setSuccess(false)
                setVailed(false)
            },2000)
          } catch (err) {
            setVailed(err.message || "Something went wrong");
          } finally {
            setLoadings(false);
          }
    }
    if (error) {
        return (
            <div className="w-full p-3 bg-red-500 rounded-lg text-center">
                {error}
            </div>
        )
    }
    return (
        <div>
            <div className="flex mb-2 justify-between">
                <p className="font-semibold">PIN Aktivasi</p>
                <p className="text-blue-500 font-bold">Srok PIN : {data ? data.pin_active : 0}</p>
            </div>
            <div className="w-full grid grid-cols-3">
                <Link to={`/pages/beli-pin`}>
                    <div className="flex flex-col justify-center items-center text-xs">
                        <div className="rounded-full bg-green-100 w-12 h-12 flex justify-center items-center">
                            <IconBuy className={`w-7 h-7 text-green-600`} />
                        </div>
                        <p className="mt-1">Beli PIN</p>
                    </div>
                </Link>
                <Link to={`/pages/riwayat-pin`}>
                    <div className="flex flex-col justify-center items-center text-xs">
                        <div className="rounded-full bg-blue-100 w-12 h-12 flex justify-center items-center">
                            <IconHistory className={`w-7 h-7 text-blue-600`} />
                        </div>
                        <p className="mt-1">Riwayat PIN</p>
                    </div>
                </Link>
                <Link to={`/pages/transfer-pin`}>
                    <div className="flex flex-col justify-center items-center text-xs">
                        <div className="rounded-full bg-purple-100 w-12 h-12 flex justify-center items-center">
                            <IconTransfer className={`w-7 h-7 text-purple-600`} />
                        </div>
                        <p className="mt-1">Transfer PIN</p>
                    </div>
                </Link>

            </div>
            <p className="mb-2 mt-6 font-bold">Jaringan</p>
            <div className="w-full grid grid-cols-3">
                <Link to={`/pages/sponsoring`}>
                    <div className="flex flex-col justify-center items-center text-xs">
                        <div className="rounded-full bg-pink-100 w-12 h-12 flex justify-center items-center">
                            <IconSponsor className={`w-7 h-7 text-pink-600`} />
                        </div>
                        <p className="mt-1">Sponsorring</p>
                    </div>
                </Link>
                <Link to={`/pages/geneology`}>
                    <div className="flex flex-col justify-center items-center text-xs">
                        <div className="rounded-full bg-sky-100 w-12 h-12 flex justify-center items-center">
                            <IconGroup className={`w-7 h-7 text-sky-600`} />
                        </div>
                        <p className="mt-1">Geneology</p>
                    </div>
                </Link>
                <Link to={`/pages/register`}>
                    <div className="flex flex-col justify-center items-center text-xs">
                        <div className="rounded-full bg-orange-100 w-12 h-12 flex justify-center items-center">
                            <IconAddUser className={`w-7 h-7 text-orange-600`} />
                        </div>
                        <p className="mt-1">Daftar</p>
                    </div>
                </Link>
            </div>
            <div className="w-full grid grid-cols-3 gap-3 mt-6">
                <div className="bg-blue-100 px-2 py-1 w-full border-2 border-blue-200 rounded-lg flex flex-col justify-center items-center">
                    <p className="text-sm text-blue-800">Kaki Kiri</p>
                    <p className="text-blue-800 font-bold">{data ? data.count_left : 0}</p>
                </div>
                <div className="bg-green-100 px-2 py-1 w-full border-2 border-green-200 rounded-lg flex flex-col justify-center items-center">
                    <p className="text-sm text-green-800">Sponsoring</p>
                    <p className="text-green-800 font-bold">{data ? data.sponsoring : 0}</p>
                </div>
                <div className="bg-orange-100 px-2 py-1 w-full border-2 border-orange-200 rounded-lg flex flex-col justify-center items-center">
                    <p className="text-sm text-orange-800">Kaki Kiri</p>
                    <p className="text-orange-800 font-bold">{data ? data.count_right : 0}</p>
                </div>
            </div>
            {username === "sebara" &&(
                <div className="bg-white shadow-md px-4 py-2 w-full border border-gray-200 rounded-lg flex flex-col mt-6">
                <p className="text-center font-bold mb-2">Genrate PIN Aktivasi</p>
                {success && (
                    <div className="w-full p-2 bg-blue-300 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                {vailed && (
                    <div className="w-full p-2 bg-red-500 rounded-lg text-center text-white mb-6">{message}</div>
                )}
                <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="etxt-semibold mb-1">Jumlah PIN <span className="text-red-500">*</span></label>
                    <input type="number"
                        name="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500" 
                        placeholder="0" 
                        required
                        />
                </div>
                <div className="mb-4">
                    <label className="etxt-semibold mb-1">Jenis Paket <span className="text-red-500">*</span></label>
                    <select 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-blue-500"
                        required
                        name="paket"
                        value={paket}
                        onChange={(e) => setPaket(e.target.value)}
                        >
                        <option value={`0`}>Pilih Paket</option>
                        {!paketError && paketData && paketData.map((item,index)=>(
                            <option key={index} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" 
                    className="w-full mt-5 text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                        Genrate
                </button>
                </form>
            </div>
            )}
        </div>
    )
}
export default Jaringan