import IconUser from "../../icon/IconUser"

const BoxUser = ({onClick,username,left,right}) => {
    return (
        <div
            className="flex flex-col cursor-pointer p-2 rounded-lg text-sm border bg-green-100 border-green-400 shadow-md mb-2 justify-center items-center"
            onClick={onClick}
        >
            <div className="h-9 w-9 rounded-full bg-green-200 flex justify-center items-center mb-1">
            <IconUser className="w-7 h-7 text-green-500" />
            </div>
            <div className="flex flex-col justify-center items-center">
                <span className="font-bold">{username}</span>
                <span>{left} | {right}</span>
            </div>
        </div>
    )
}
export default BoxUser