import { useState, useCallback } from "react";

const useGeneology = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const showGeneology = useCallback(async (username) => {
    setLoading(true);
    setError(null);

    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "SebaraInvinityInternasional");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ username });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("/api/geneology", requestOptions);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setData(result.data);
      return result.data; // Return data directly for immediate use
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, []);

  return { showGeneology, loading, error, data };
};

export default useGeneology;
