import { useEffect,useState } from "react"
import useProduct from "../hook/useProduct"
import IconMinus from "../icon/IconMInus"
import IconPlus from "../icon/IconPlus"
import { FormatRupiah } from "@arismun/format-rupiah";
import Loading from "../components/Loading";

const Market = () => {
    const [loading,setLoading] = useState()
    const { showProduct, data } = useProduct()
    const [qty, setQty] = useState({});
   
    useEffect(() => {
        showProduct()
    }, [showProduct])

    const handleIncrement = (id) => {
        setQty((prevQty) => ({
            ...prevQty,
            [id]: (prevQty[id] || 1) + 1,
        }));
    };

    const handleDecrement = (id) => {
        setQty((prevQty) => ({
            ...prevQty,
            [id]: prevQty[id] > 1 ? prevQty[id] - 1 : 1,
        }));
    };
    const handlePurchase = async (item) => {
        const quantity = qty[item.id] || 1; 
        const subtotal = item.price * quantity;
        const desc = `Pembelian Product ${item.name} Sebara Invibity`
        const prices = parseFloat(subtotal) + (3000 + (0.002 * parseFloat(subtotal)))
        setLoading(true)
        const formData = {
            quantity,
            price:item.price,
            subtotal : prices,
            desc,
            username:localStorage.getItem("username")
          };
          try {
            const response = await fetch("/api/beli-product", {
              method: "POST",
              headers: {
                "x-api-key": "SebaraInvinityInternasional",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            });
      
            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }
      
            const results = await response.json();
            window.location.href = results.paymentUrl
            
          } catch (err) {
            
          } finally {
            setLoading(false);
          }
    };
    if(loading){
        return (<Loading/>)
    }
    return (
        <div className="w-full grid grid-cols-2 gap-4">
            {data && data.length > 0 && 
                data.map((item,index)=>(
                    <div key={index} className="bg-white shadow-md w-full border border-gray-200 rounded-lg flex flex-col">
                        <img
                            src={item.image}
                            className="m-1 rounded-tl-lg rounded-tr-lg h-[175px]"
                            alt={item.name}
                        />
                        <div className="m-3">
                            <p className="text-sm font-bold mb-1 truncate">{item.name}</p>
                            <p className="font-bold text-blue-500"><FormatRupiah value={item.price}/></p>
                            <div className="flex mt-2 ">
                                <div onClick={() => handleDecrement(item.id)} className="cursor-pointer">
                                    <IconMinus className={`text-red-700`} />
                                </div>
                                <div className="w-full flex mx-3 justify-center border-2 border-gray-100 bg-gray-50 text-center text-xs items-center rounded-full">
                                {qty[item.id] || 1}
                                </div>
                                <div onClick={() => handleIncrement(item.id)} className="flex items-right cursor-pointer">
                                    <IconPlus className={`text-green-700`} />
                                </div>
                            </div>
                            <div
                                onClick={() => handlePurchase(item)} 
                                className="w-full text-white bg-gradient-to-br from-blue-500 to-purple-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-1 mt-2 text-center">
                                Beli
                            </div>
                        </div>
                    </div>
                ))
            }


        </div>
    )
}
export default Market